
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































.consentement-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));
}
button {
  border: none;
  border-radius: 50px;
  background-color: $c-main-primary;
  color: white;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px 8px 12px;
}
button.action {
  align-items: center;
  background: rgba(247, 166, 0, 0.2);
  border-radius: 50px;
  color: $c-main-primary;
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  padding: 0.4em 5px 0.4em 1.5em;
}
button.action--big--btn {
  ::v-deep {
    padding: 1.4rem 2.8rem;
  }
}
.doc-button button,
.doc-button button.action--btn,
.doc-button button.action--big--btn,
.doc-button button.action--big--btn.checked {
  margin-bottom: 0;
  padding: 1.3rem 2.8rem;
  width: 100%;
}
button.action--big--btn:disabled {
  background-color: #ececec;
  color: $c-gray-dark;
  padding: 1rem 2.8rem;
}
.action__label {
  display: block;
  width: 100%;
}
.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
button:hover,
a:hover {
  opacity: 0.7;
}
