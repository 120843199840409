
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























































































































































































































































































































































































































































































































































































































































































































.card {
  border-radius: 10px;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
  font-family: $ff-default;
  margin: 10px 0;
  max-width: 750px;
  padding: 20px 20px;
}
.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.title {
  color: grey;
  font-size: 18px;
}
.action__label {
  display: block;
  width: 100%;
}
button {
  border: none;
  border-radius: 50px;
  background-color: $c-main-primary;
  color: white;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px 8px 12px;
}
button.action {
  align-items: center;
  background: rgba(247, 166, 0, 0.2);
  border-radius: 50px;
  color: $c-main-primary;
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  padding: 0.4em 5px 0.4em 1.5em;
}
.icon-close-open {
  background-color: $c-main-primary;
  background-image: url('../assets/images/ico-close.svg');
  background-size: 13px 7px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  display: inline-block;
  height: 34px;
  margin-left: 8px;
  transition-property: rotate;
  transition-duration: 3s, 5s;
  width: 34px;
}
button.action.closed span.icon-close-open {
  transform: rotate(180deg);
}
a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}
p {
  color: $c-gray-darker;
}
button:hover,
a:hover {
  opacity: 0.7;
}
button.action--big--btn {
  ::v-deep {
    padding: 1.4rem 2.8rem;
  }
}
button.action--big--btn.checked {
  background-color: white;
  border: 1px solid #5dae99;
  background-image: url('../assets/images/ico-check-green.svg');
  background-position: 15px center;
  background-repeat: no-repeat;
  color: #5dae99;
  padding-left: 40px;
  text-align: right;
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: center;
}
.send-file {
  p {
    color: #ff4b00;
  }
  button {
    margin-top: 1em;
  }
}
.emphase-text {
  color: $c-main-primary;
  font-weight: bold;
}
.record-title {
  background-image: url('../assets/images/ico-thumb.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 60px 60px;
  color: $c-main-primary;
  font-family: $ff-alt;
  padding: 15px 0 15px 65px;
}
.header-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.warning-banner {
  background-color: rgba(247, 166, 0, 0.2);
  display: block;
  color: $c-gray-darker;
  margin: 10px -20px;
  padding: 1.1em 20px;
}
.warning-bannerb {
  background-color: rgba(247, 166, 0, 0.2);
  display: block;
  color: $c-gray-darker;
  margin: 10px -20px;
  padding: 1.1em 20px;
}
.warning-bannerb em {
  color: $c-main-primary;
  font-style: normal;
}
.card .warning-bannerb {
  margin-top: -20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.warning-banner em {
  color: $c-main-primary;
  font-style: normal;
}
.ean-id {
  color: $c-gray;
}
.end {
  display: flex;
  justify-content: flex-end;
}
.documents-list {
  font-size: 0.85em;
}
.not-send {
  background-color: #fff;
  background-image: url('../assets/svg/icons/ico-alert.svg');
  background-position: 15px;
  background-repeat: no-repeat;
  color: #9d9c9d;
  width: 100%;
  pointer-events: none;
  padding: 0 !important;
}
.documents-list .flex-row {
  padding: 5px 0;
}
.doc-status {
  background-image: url('../assets/svg/icons/ico-alert.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 16px 14px;
  color: $c-main-primary;
  padding-left: 20px;
}
.checkbox {
  display: flex;
  flex-direction: row;
}
.checkbox label {
  color: $c-gray;
}
.checkbox.warning label {
  color: $c-main-primary;
}
.warning input[type='checkbox'] {
  border: 1px solid $c-main-primary;
  height: 21px;
  margin-right: 8px;
  width: 21px;
}
.doc-button button,
.doc-button button.action--btn,
.doc-button button.action--big--btn,
.doc-button button.action--big--btn.checked {
  margin-bottom: 0;
  padding: 1.3rem 2.8rem;
  width: 100%;
}
button.action--big--btn:disabled {
  background-color: #ececec;
  color: $c-gray-dark;
  padding: 1rem 2.8rem;
}
@media screen and (min-width: 960px) {
  .card {
    width: 750px;
  }

  .doc-button {
    width: 20%;
  }
  .checkbox {
    width: 80%;
  }
}
.icon-elec {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: center url('../assets/svg/icons/electricityflood.svg') no-repeat;
  background-size: contain;
}
.icon-gaz {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: center url('../assets/svg/icons/gazflood.svg') no-repeat;
  background-size: contain;
}
